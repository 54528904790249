import { useState, useEffect } from "react";

const useFormData = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    recipientFirstName: "",
    recipientLastName: "",
    recipientEmail: "",
    sendGiftOn: "",
    personalizedUsb: false,
    personalizedVinyl: false,
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const saveFormData = () => {
    const safeFormData = { ...formData };
    delete safeFormData.password;
    delete safeFormData.confirmPassword;
    localStorage.setItem("paymentFormData", JSON.stringify(safeFormData));
  };

  const loadFormData = () => {
    const storedData = localStorage.getItem("paymentFormData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setFormData((prevData) => ({
        ...prevData,
        ...parsedData,
      }));
    }
  };

  useEffect(() => {
    loadFormData();
  }, []);

  useEffect(() => {
    const form = document.getElementById("payment-form");
    if (form) {
      form.addEventListener("input", saveFormData);

      return () => {
        form.removeEventListener("input", saveFormData);
      };
    }
  }, [formData]);

  return { formData, handleChange, error, setError };
};

export default useFormData;
